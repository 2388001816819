:root {
    --text-black: #101828;
    --text-light-gray: #667085;
}

#lola__body {
    background-color: #f9fafb;
    width: 100%;
}

.gradient__bg {
    background: linear-gradient(
        45deg,
        #00131e 0%,
        #2a3948 80.21%,
        #475467 100%
    );
}

.dotted__green {
    background-image: radial-gradient(circle, #b3ffdc 2%, transparent 10%),
        radial-gradient(circle, #b3ffdc 2%, transparent 10%);
    background-size: 20px 10px;
    background-position: 0 0, 50px 50px;
}

.dotted__brown {
    background-image: radial-gradient(circle, #fcdcb5 2%, transparent 10%),
        radial-gradient(circle, #fcdcb5 2%, transparent 10%);
    background-size: 20px 10px;
    background-position: 0 0, 50px 50px;
}

.dotted__blue {
    background-image: radial-gradient(circle, #b3e4ff 2%, transparent 10%),
        radial-gradient(circle, #b3e4ff 2%, transparent 10%);
    background-size: 20px 10px;
    background-position: 0 0, 50px 50px;
}

.bg__blue {
    background: #009ff514;
}

.bg__blue:hover {
    background: #00538014;
}

.circular--landscape img {
    border-radius: 50%;
}

.savings__card {
    background: rgba(247, 85, 0, 0.06);
    border-radius: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 700px) {
    body {
        font-size: 18px;
    }

    .accordion {
        width: 90%;
    }
}

/* Card Styles */
.card {
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid #ebedf3;
    border-radius: 0.42rem;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 2.25rem;
}

/* End of Card Styles */

/* React Suites Progress bar */
.rs-progress-circle-info {
    font-family: "AvenirBook", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    padding-top: 5px;
}

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.mobile-menu-shadow {
    box-shadow: -4px -3px 6px rgba(0, 0, 102, 0.1),
        5px 4px 6px rgba(0, 0, 102, 0.1);
}

.mobile-menu a {
    text-decoration: none;
}

@media screen and (max-width: 640px) {
    .container.community_details {
        width: calc(100vw - 2rem);
        overflow: hidden;
    }
}

#fc_frame {
    margin-bottom: 50px;
}

.circles--slick-override .slick-dots li {
    margin: 0 -2px;
}
.circles--slick-override .slick-dots li.slick-active button::before {
    opacity: 1;
}

.circles--slick-override .slick-dots li button::before,
.circles--slick-override .slick-dots li.slick-active button::before {
    font-size: 11px;
    color: #f75500;
}
.transform-matrix {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
