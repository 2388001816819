@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --lola-primary: 0, 159, 245;
        --lola-green: 22, 83, 1;
        --lola-pink: 186, 29, 200;
        --lola-brown: 153, 92, 18;
        --lola-light-brown: 246, 147, 2;
        --lola-blue: 25, 38, 255;
    }

    @font-face {
        font-family: "GilroyBold";
        src: url("../fonts/Gilroy/Gilroy-ExtraBold.otf");
    }

    @font-face {
        font-family: "GilroyLight";
        src: url("../fonts/Gilroy/Gilroy-Light.otf");
    }

    @font-face {
        font-family: "GilroyExtraBold";
        src: url("../fonts/Gilroy/Gilroy-ExtraBold.otf");
    }

    @font-face {
        font-family: "AvenirBook";
        src: url("../fonts/avenir/AvenirLTStd-Book.otf");
    }
}
@layer utilities {
    .lola_container {
        @apply container mx-auto;
    }
}

.card__base {
    @apply bg-white rounded-lg;
    box-shadow: 0px 4px 12px rgba(22, 83, 1, 0.05);
}

.blurred__text {
    @apply text-transparent;
    text-shadow: 0 0 10px #ccc;
}

.blurred__text--black {
    @apply text-transparent;
    text-shadow: 0 0 8px #000;
}
